import React, { useState, useEffect } from 'react';
import videomain from '../../assets/videos/main1.mp4';
function banner(){
    const videoStyle = {
        position: 'relative',  // or 'absolute' depending on your layout needs
        zIndex: 1,            // Adjust the z-index value as needed
        width: '100%',
        height: 'auto',
      };
return(
   
    <div className='padding'>
         <br></br>
    {/* <img src={ImageNavbar} alt="Image" className="image-below-navbar" /> */}
   
    <video width="100%" autoPlay muted loop style={videoStyle}>
    <source src={videomain} type="video/mp4" />
{/* You can add more source tags for different video formats */}

</video>
    <div className="image-text">
        <h2>Klein Condensed Trial <br />
            Roller Fashions</h2>  
        <p>Bold can look effortless. Discover the spring <br />
            collection.</p>
    </div>
</div>

)

}
export default banner;