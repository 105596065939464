import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import '../../assets/css/trendingNow.css';
import BigImage from "../../assets/images/bigwithimage.jpg"
import Circle from "../../assets/images/Ellipse.png"


import { FaRegHeart } from "react-icons/fa";

const TrendingNow = () => {
    return (
       <div className='tooltip-container'>
            <Image src={BigImage} className='bigImage'></Image>
           <a href='#' data-tooltip='Collar Checks'  className='small_circle tooltips firstimg'></a>
           <a href='#'  data-tooltip='Collar T Shirt'  className='small_circle tooltips secondimg'></a>
           <a href='#'  data-tooltip='Round Neck T Shirt'className='small_circle tooltips thirdimg'></a>
           <a href='#'  data-tooltip='Plain Shirts' className='small_circle tooltips fourthimg'></a>
           <a href='#'  data-tooltip='Hoodies' className='small_circle tooltips fifthimg'></a>
           </div>
    );
}

export default TrendingNow;
