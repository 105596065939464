
import React, { useState, useEffect } from 'react';
import { Navbar, Nav, Form, Dropdown, Col, Container, Row } from 'react-bootstrap';
import { FaUserCircle, FaSearch } from 'react-icons/fa';
import axios from 'axios';
import logo from "../assets/images/favicon.png";
import "../assets/css/adminheader.css";

function AdminHeader() {
    const [username, setUsername] = useState('User');
    const authorid = localStorage.getItem("authorid");

    useEffect(() => {
        const fetchUsername = async () => {
            try {
                const response = await axios.get(`https://api.rollerfashions.com/api/Admin/Get_Admin`, {
                    params: { id: authorid },
                });
                if (response.data && response.data.username) {
                    setUsername(response.data.username);
                }
            } catch (error) {
                console.error('Error fetching username:', error);
            }
        };

        fetchUsername();
    }, [authorid]);

    const handleLogout = () => {
        localStorage.clear(); // Clear localStorage on logout
        window.location.href = "/adminlogin"; // Redirect to login page
    };

    return (
        <Navbar expand="lg" fixed="top" className="p-0">
            <Container fluid>
                <Row className="w-100">
                    {/* Logo Col */}
                    <Col xs={2} className="d-flex align-items-center">
                    
                    </Col>

                    {/* Search Col */}
                    <Col xs={6} className="search-container d-flex align-items-center justify-content-center">
                        {/* <Form className="nav-link mt-2 mt-md-0 d-none d-lg-flex">
                            <div className="input-group">
                                <input type="text" placeholder="Search..." className="form-control" />
                                <div className="input-group-append">
                                    <span className="input-group-text">
                                        <FaSearch />
                                    </span>
                                </div>
                            </div>
                        </Form> */}
                    </Col>

                    {/* Navbar Profile Col */}
                    <Col xs={3} className="d-flex align-items-center justify-content-end mt-3">
                        <Nav>
                            <Dropdown >
                                <Dropdown.Toggle variant="dark" id="profileDropdown">
                                    
                                        
                                        {username}
                                
                                </Dropdown.Toggle>
                                <Dropdown.Menu className='customdrp'>
                                    <Dropdown.Item href="/adminprofile">Profile</Dropdown.Item>
                                    <Dropdown.Divider />
                                    <Dropdown.Item onClick={handleLogout}>Log out</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Nav>
                    </Col>
                </Row>
            </Container>
        </Navbar>
    );
}

export default AdminHeader;
