// import React from 'react';
// import { Container, Row, Col, Form, Button,Image } from 'react-bootstrap';
// import Sidenav from './LeftMenu';
// import Logo from '../assets/images/Main_logo.png';
// function ProductList() {
//     return (
//         <div class="container-fluid">
//             <Col md={2}>
//             <nav class="navbar p-0 fixed-top d-flex flex-row">
//                     <div class="navbar-brand-wrapper d-flex d-lg-none align-items-center justify-content-center">
//                         <a class="navbar-brand brand-logo-mini" href="index.html"><Image src={Logo} fluid className="Logo" /></a>
//                     </div>
//                     <div class="navbar-menu-wrapper flex-grow d-flex align-items-stretch">
//                         <button class="navbar-toggler navbar-toggler align-self-center" type="button" data-toggle="minimize">
//                             <span class="mdi mdi-menu"></span>
//                         </button>
//                         <ul class="navbar-nav w-100">
//                             <li class="nav-item w-100">
//                                 <form class="nav-link mt-2 mt-md-0 d-none d-lg-flex search">
//                                     <input type="text" class="form-control" placeholder="Search products" />
//                                 </form>
//                             </li>
//                         </ul>
//                         <ul class="navbar-nav navbar-nav-right">
//                             <li class="nav-item  dropdown d-none d-lg-block">
//                             </li>
//                             <li class="nav-item dropdown border-left">
//                                 <a class="nav-link count-indicator dropdown-toggle" id="notificationDropdown" href="#" data-toggle="dropdown">
//                                     <i class="mdi mdi-bell"></i>
//                                     <span class="count bg-danger"></span>
//                                 </a>
//                                 <div class="dropdown-menu dropdown-menu-right navbar-dropdown preview-list" aria-labelledby="notificationDropdown">
//                                     <h6 class="p-3 mb-0">Notifications</h6>
//                                     <div class="dropdown-divider"></div>
//                                     <a class="dropdown-item preview-item">
//                                         <div class="preview-thumbnail">
//                                             <div class="preview-icon bg-dark rounded-circle">
//                                                 <i class="mdi mdi-calendar text-success"></i>
//                                             </div>
//                                         </div>
//                                         <div class="preview-item-content">
//                                             <p class="preview-subject mb-1">Event today</p>
//                                             <p class="text-muted ellipsis mb-0"> Just a reminder that you have an event today </p>
//                                         </div>
//                                     </a>
//                                     <div class="dropdown-divider"></div>
//                                     <a class="dropdown-item preview-item">
//                                         <div class="preview-thumbnail">
//                                             <div class="preview-icon bg-dark rounded-circle">
//                                                 <i class="mdi mdi-settings text-danger"></i>
//                                             </div>
//                                         </div>
//                                         <div class="preview-item-content">
//                                             <p class="preview-subject mb-1">Settings</p>
//                                             <p class="text-muted ellipsis mb-0"> Update dashboard </p>
//                                         </div>
//                                     </a>
//                                     <div class="dropdown-divider"></div>
//                                     <a class="dropdown-item preview-item">
//                                         <div class="preview-thumbnail">
//                                             <div class="preview-icon bg-dark rounded-circle">
//                                                 <i class="mdi mdi-link-variant text-warning"></i>
//                                             </div>
//                                         </div>
//                                         <div class="preview-item-content">
//                                             <p class="preview-subject mb-1">Launch Admin</p>
//                                             <p class="text-muted ellipsis mb-0"> New admin wow! </p>
//                                         </div>
//                                     </a>
//                                     <div class="dropdown-divider"></div>
//                                     <p class="p-3 mb-0 text-center">See all notifications</p>
//                                 </div>
//                             </li>
//                             <li class="nav-item dropdown">
//                                 <a class="nav-link" id="profileDropdown" href="#" data-toggle="dropdown">
//                                     <div class="navbar-profile">
//                                         <img class="img-xs rounded-circle" src="assets/images/faces/face15.jpg" alt="" />
//                                         <p class="mb-0 d-none d-sm-block navbar-profile-name">Henry Klein</p>
//                                         <i class="mdi mdi-menu-down d-none d-sm-block"></i>
//                                     </div>
//                                 </a>
//                                 <div class="dropdown-menu dropdown-menu-right navbar-dropdown preview-list" aria-labelledby="profileDropdown">
//                                     <h6 class="p-3 mb-0">Profile</h6>
//                                     <div class="dropdown-divider"></div>
//                                     <a class="dropdown-item preview-item">
//                                         <div class="preview-thumbnail">
//                                             <div class="preview-icon bg-dark rounded-circle">
//                                                 <i class="mdi mdi-settings text-success"></i>
//                                             </div>
//                                         </div>
//                                         <div class="preview-item-content">
//                                             <p class="preview-subject mb-1">Settings</p>
//                                         </div>
//                                     </a>
//                                     <div class="dropdown-divider"></div>
//                                     <a class="dropdown-item preview-item">
//                                         <div class="preview-thumbnail">
//                                             <div class="preview-icon bg-dark rounded-circle">
//                                                 <i class="mdi mdi-logout text-danger"></i>
//                                             </div>
//                                         </div>
//                                         <div class="preview-item-content">
//                                             <p class="preview-subject mb-1">Log out</p>
//                                         </div>
//                                     </a>
//                                     <div class="dropdown-divider"></div>
//                                     <p class="p-3 mb-0 text-center">Advanced settings</p>
//                                 </div>
//                             </li>
//                         </ul>
//                         <button class="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" data-toggle="offcanvas">
//                             <span class="mdi mdi-format-line-spacing"></span>
//                         </button>
//                     </div>
//                 </nav>
//             </Col>
//             <Container fluid className="page-body-wrapper">


//                 <div class="main-panel">
//                     <div class="content-wrapper">
//                         <Col md={12} className="grid-margin stretch-card">
//                             <div class="card">
//                                 <div class="card-body">
//                                     <h4 class="card-title">Products List</h4>

//                                     <div class="table-responsive">
//                                         <table class="table table-hover">
//                                             <thead>
//                                                 <tr>
//                                                     <th>Shirts</th>
//                                                     <th>Category</th>
//                                                     <th>Last Modified</th>
//                                                     <th>Sale</th>
//                                                     <th>Stock</th>
//                                                 </tr>
//                                             </thead>
//                                             <tbody>
//                                                 <tr>
//                                                     <td>T shirts</td>
//                                                     <td>Photoshop</td>
//                                                     <td>10/6/1990</td>
//                                                     <td class="text-danger"> 28.76% <i class="mdi mdi-arrow-down"></i></td>
//                                                     <td><label class="badge badge-danger">20</label></td>
//                                                 </tr>
//                                                 <tr>
//                                                     <td>Printed Shirts</td>
//                                                     <td>Flash</td>
//                                                     <td>10/6/1990</td>
//                                                     <td class="text-danger"> 21.06% <i class="mdi mdi-arrow-down"></i></td>
//                                                     <td><label class="badge badge-warning">55</label></td>
//                                                 </tr>
//                                                 <tr>
//                                                     <td>T shirts</td>
//                                                     <td>Premier</td>
//                                                     <td>10/6/1990</td>
//                                                     <td class="text-danger"> 35.00% <i class="mdi mdi-arrow-down"></i></td>
//                                                     <td><label class="badge badge-warning">55</label></td>
//                                                 </tr>
//                                                 <tr>
//                                                     <td>Peter</td>
//                                                     <td>After effects</td>
//                                                     <td>10/6/1990</td>
//                                                     <td class="text-success"> 82.00% <i class="mdi mdi-arrow-up"></i></td>
//                                                     <td><label class="badge badge-success">100+</label></td>
//                                                 </tr>
//                                                 <tr>
//                                                     <td>Dave</td>
//                                                     <td>53275535</td>
//                                                     <td>10/6/1990</td>
//                                                     <td class="text-danger"> 98.05% <i class="mdi mdi-arrow-up"></i></td>
//                                                     <td><label class="badge badge-warning">51</label></td>
//                                                 </tr>
//                                             </tbody>
//                                         </table>
//                                     </div>
//                                 </div>
//                             </div>
//                         </Col>
//                     </div>
//                 </div>

//             </Container>
//         </div>

//     )
// }
// export default ProductList;
import React, { useState, useEffect } from 'react';
import { Table, Button, Card, CardBody, Modal } from 'react-bootstrap';
import axios from 'axios';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import Swal from 'sweetalert2';
import 'react-toastify/dist/ReactToastify.css';
import "../assets/css/productlist.css";

function ProductList() {
    const [products, setProducts] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');
    const token = localStorage.getItem("responsetoken");
    const authorid = localStorage.getItem("authorid");

    useEffect(() => {
        fetchProducts();
    }, []);

    const fetchProducts = async () => {
        try {
            const response = await axios.get("https://api.rollerfashions.com/api/Product/List_Product", {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'content-type': 'application/json',
                },
            });
            setProducts(response.data);
        } catch (error) {
            console.error("Error fetching products:", error);
            toast.error("An error occurred while fetching products. Please try again.", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    };

    const handleDelete = async (productId) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const url = `https://api.rollerfashions.com/api/Product/Delete_Product`;
                    const obj = {
                        id: productId,
                        author_ID: authorid
                    };

                    const response = await axios.post(url, obj, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`,
                        }
                    });

                    toast.success(response.data.responsemessage, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });

                    fetchProducts();
                    Swal.fire(
                        'Deleted!',
                        'Your product has been deleted.',
                        'success'
                    );
                } catch (error) {
                    console.error("Error deleting product:", error);
                    toast.error("An error occurred while deleting the product. Please try again.", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                }
            }
        });
    };

    const handleImageClick = (image) => {
        setSelectedImage(image);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedImage('');
    };

    return (
        <div className="container-fluid content-wrapper">
            <Card>
                <CardBody>
                <h2 className='coupon-title'>Product Management / Product List</h2>
                    {/* <h2 className='primary'>Product List</h2> */}
                    <Table className='table table-dark'>
                        <thead>
                            <tr>
                                <th>Display Image</th>
                                <th>Name</th>
                                <th>SKU Number</th>
                                <th>Price</th>
                                <th>Selling Price</th>
                                <th>Category</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {products && products.map(product => (
                                <tr key={product.id}>
                                    <td>
                                        <img
                                            src={`${product.display_image}`}
                                            alt={product.name}
                                            className="product-list-image"
                                            onClick={() => handleImageClick(product.display_image)}
                                        />
                                    </td>
                                    <td className="product-name">{product.name}</td>
                                    <td>{product.skq_number}</td>
                                    <td>{product.price}</td>
                                    <td>{product.selling_price}</td>
                                    <td>{product.category}</td>
                                    <td>
                                        <Link to={`/editproduct/${product.id}`}>
                                            <FaEdit />
                                        </Link>
                                        <Button variant="danger" className="action-button" onClick={() => handleDelete(product.id)}>
                                            <FaTrash />
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </CardBody>
            </Card>

            <Modal show={showModal} onHide={handleCloseModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Product Image</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <img src={`${selectedImage}`} alt="Product" className="img-fluid" />
                </Modal.Body>
            </Modal>

            <ToastContainer />
        </div>
    );
}

export default ProductList;