
import React, { useState, useEffect } from 'react';
import { Form, Button, Container, Row, Col, Card, Image, CardBody, CardHeader, CardTitle, Navbar, Nav, NavDropdown } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faSearch, faTimes, faSignIn, faHeart as solidHeart } from '@fortawesome/free-solid-svg-icons';
import ImageNavbar from '../../assets/images/Carousel.svg';

import $ from 'jquery';
import { Link } from 'react-router-dom';
import "../../assets/css/header.css";
import "../../assets/css/Login.css";
import OtpForm from './OTP';
import loginImage from '../../assets/images/auth.png'; // Adjust path as needed
import logo from '../../assets/images/logo.png'; // Adjust path as needed
import Cart_img from '../../assets/images/Cart.png'; // Adjust path as needed
import auth_img from '../../assets/images/user.png';
import heart_img from '../../assets/images/Heart-1.svg';
import menubars from '../../assets/images/pepicons-pencil_menu.svg';
import { FaFontAwesome } from 'react-icons/fa';
import { unstable_renderSubtreeIntoContainer } from 'react-dom';


function Header() {
  const [number, setNumber] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [otp, setOtp] = useState('');
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const handleOtpChange = (value) => {
    setOtp(value);
    console.log("Entered OTP:", value);
  };
  const checkInput = (e) => {
    const onlyDigits = e.target.value.replace(/\D/g, "");
    setNumber(onlyDigits);
  };
  $("#sidenav").hide();
  const [isOpen, setIsOpen] = useState(true);

  const toggleSidebarOpen = () => {
    debugger;
    $("#sidenav").fadeIn(500);
    return false;
  };
  const toggleSidebarClose = () => {
    $("#sidenav").fadeOut(300);
    return false;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Final OTP Submitted:", otp);
    // Add your OTP verification logic here
  };

  useEffect(() => {
    fetchBanners();

  }, []);
  useState(() => {
    $("#sidenav").hide();

  }, []);

  const fetchBanners = async () => {
    fetch('https://api.rollerfashions.com/api/Home/Home_Category') // Replace with your API endpoint
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
      
        setData(data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }; // Empty dependency array means this effect runs once on mount

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <>
      <div className="bg-light text-black sidebar" id='sidenav' >
        <div className='side_navheader'>
          <Row >
            <Col sm='8'><Button className='menu_toggle_button_inner larger' onClick={handleShow}>Signin &nbsp;<FontAwesomeIcon color='white' size='1x' icon={faSignIn} ></FontAwesomeIcon></Button></Col>
            <Col sm='4'><Button className='menu_toggle_button' onClick={toggleSidebarClose}><FontAwesomeIcon icon={faTimes} color='white' size='2x'></FontAwesomeIcon></Button></Col>
          </Row>
        </div>
        <div className='sidenavcontent'>
          <ul className="nav flex-column">

            {data.map((item, index) => (
              <li className="nav-item">
                <h6>{item.name}</h6>
                <ul className=''>
                  {item.subcat.map((subitem) => (
                    <li><img width={25} src={subitem.image}></img> <a href="#link1">{subitem.name}</a></li>

                  ))}
                </ul>
                <hr />
              </li>


            ))}
            <li className="nav-item">
              <a href="#about"> <h6>Combo</h6></a>
            </li>
            <hr></hr>
            <li className="nav-item">
              <a href="#contact"><h6>Sales</h6></a>
            </li>

          </ul>
        </div>
      </div>
      <Navbar bg="light" className="padding">
        <Container fluid >
          <Nav className="me-auto">

            <Button onClick={toggleSidebarOpen} className="menu_toggle_button">
              <Image src={menubars} width={40} />
            </Button>


            <Navbar.Brand className="ml-auto" href="#home"><Image className='brand-image' src={logo} /></Navbar.Brand>
          </Nav>


          <Nav className="ms-auto"><div class="search-box"><FontAwesomeIcon icon={faSearch} className='fa-search' size='1x'></FontAwesomeIcon><input type='text' placeholder='Search' className='form-control large-textbox'></input></div> </Nav>
          <Nav className="ms-auto">
             <Image src={heart_img} width={50} ></Image>
              &nbsp;&nbsp;
            <Image src={Cart_img} width={50}></Image>&nbsp;&nbsp;
            <Image src={auth_img} width={50} onClick={handleShow}></Image>
          </Nav>

          <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} dialogClassName="custom_model modal-lg">
            <Modal.Header className='pt-reduced pb-reduced' closeButton>

            </Modal.Header>
            <Modal.Body closeButton>

              <Container fluid className="login-container">
                <Row className="justify-content-center align-items-center">
                  <Col md={5} className="login-image-col">
                    <Image src={loginImage} fluid className="login-image" />
                  </Col>
                  <Col md={6}>

                    <Card>
                      <center><h3>Login / Signup</h3></center>
                      <br>
                      </br><br></br>
                      <Form>
                        <Form.Group controlId="formBasicEmail">
                          <Form.Label>Entire mobile number</Form.Label>
                          <Form.Control
                            type="tel"
                            maxLength={10}
                            placeholder="+91"
                            value={number}
                            onChange={(e) => checkInput(e)}
                            required
                          />
                        </Form.Group>
                        <br></br>
                        <div id='dvotp' className='dvotp'> <Form.Group controlId="OTP">
                          <Form.Label>OTP</Form.Label>
                          <OtpForm length={4} onChange={handleOtpChange} />
                        </Form.Group>


                        </div><br></br><br></br>
                        <Button variant="primary" type="submit" className="w-100 btn-primary p-3">
                          Login
                        </Button>
                      </Form>

                    </Card></Col>
                </Row>
              </Container>
            </Modal.Body>

          </Modal>
        </Container>
      </Navbar>
    </>
  );
}

export default Header;

